import { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

import { auth } from '../config/firebase.config';
import userService, { userModul } from '../services/user.service';

const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
});

export const AuthContextProvider = props => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const getUserId = async currentUser => {
    const userId = currentUser.uid;
    const user = await userService.getById(userId);
    if (!user) {
      const newUser = userModul({ name: currentUser.displayName, email: currentUser.email });
      await userService.createOrUpdate(newUser, userId);
    }
  };

  onAuthStateChanged(auth, async currentUser => {
    if (currentUser && Object.keys(currentUser).length > 0 && currentUser.uid !== user?.uid) {
      setUser({ ...currentUser });
      await getUserId(currentUser);
    } else if ((!currentUser || Object.keys(currentUser).length === 0) && !!user?.email) {
      setUser(null);
    }
  });

  const login = useCallback(async ({ email, password, rememberMe = false }) => {
    try {
      const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
      await setPersistence(auth, persistence);
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Anmeldung erfolgreich!');
    } catch (error) {
      toast.error('Anmeldung fehlgeschlagen!');
      console.error('Login failed', error);
    }
  }, []);

  const logout = useCallback(async () => {
    await signOut(auth);
    navigate('/login');
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>{props.children}</AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export default useAuthContext;
