import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#e0f3ff',
      highlight: '#00f2ff',
      paper: '#d3ffff',
    },
  },
});

export default theme;
