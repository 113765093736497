import { Button, useMediaQuery } from '@mui/material';

const BasicButton = props => {
  const { children, smallChildren, ...otherProps } = props;

  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Button
      variant={'contained'}
      fullWidth
      sx={{ height: '100%', borderRadius: 0 }}
      {...otherProps}
    >
      {!matches && smallChildren ? smallChildren : children}
    </Button>
  );
};

export default BasicButton;
