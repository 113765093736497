import { Build } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import TimerIcon from '@mui/icons-material/Timer';
import { Box } from '@mui/material';

import useLiveModeContext from '../../context/live-mode-context';

import NavItem from './NavItem';

const Navigation = () => {
  const { songList } = useLiveModeContext();

  return (
    <Box
      className={'navigation-wrapper'}
      position={'fixed'}
      top={0}
      left={0}
      width={'100%'}
      height={'10vh'}
      display={'flex'}
      justifyContent={'space-between'}
    >
      <Box
        height={'100%'}
        flexGrow={1}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <NavItem name={'Metronome'} smallName={<TimerIcon />} link={'metronome'} rootLink />
        <NavItem name={'Songlist'} smallName={<FormatListBulletedIcon />} link={'songlist'} />
        <NavItem name={'Setlist'} smallName={<FormatListNumberedIcon />} link={'setlists'} />
        <NavItem
          name={'Livemode'}
          smallName={<PlaylistPlayIcon />}
          link={'livemode'}
          disabled={songList.length === 0}
        />
      </Box>
      <Box height={'100%'} width={'10vh'} display={'flex'} justifyContent={'center'}>
        <NavItem name={<Build />} link={'setup'} />
      </Box>
    </Box>
  );
};

export default Navigation;
