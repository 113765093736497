import { Box, Button, Typography } from '@mui/material';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography variant={'h4'}>Something went wrong:</Typography>
      <Typography>{error.message}</Typography>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </Box>
  );
};

export default ErrorFallback;
