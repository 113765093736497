import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: 'AIzaSyDAXH2DC2bVuKvKfCK_Js7ARVFjfO_YJ4E',
  authDomain: 'click-app-e3eaf.firebaseapp.com',
  projectId: 'click-app-e3eaf',
  storageBucket: 'click-app-e3eaf.appspot.com',
  messagingSenderId: '703003703874',
  appId: '1:703003703874:web:721d297191c70840158bdb',
  measurementId: 'G-CCQJB1G6QT',
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

if (process.env.NODE_ENV === 'development') {
  const firebasePort = process.env.firebasePort || 8080;
  connectFirestoreEmulator(db, 'localhost', firebasePort);
  console.log(`Using firestore emulator at localhost: ${firebasePort}!`);

  const authPort = process.env.authPort || 9099;
  connectAuthEmulator(auth, `http://localhost:${authPort}`);
  console.log(`Using firestore auth at localhost: ${authPort}!`);
}

export default firebaseApp;
export { db, auth };
