import { Suspense, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Box, useTheme } from '@mui/material';

import useAuthContext from '../firebase/auth/auth-context';
import Navigation from '../modules/Navigation/Navigation';

import LoadingSpinner from './../components/LoadingSpinner/loading-spinner';
import AppRoutes from './AppRoutes';
import ErrorFallback from './ErrorFallback';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const theme = useTheme();

  const location = useLocation();
  const isLiveMode = location.pathname.includes('/livemode');

  const { user } = useAuthContext();
  const hasNavigation = useMemo(() => !!user && !isLiveMode, [isLiveMode, user]);

  return (
    <Box
      className={'click-app'}
      width={'100vw'}
      height={'100vh'}
      backgroundColor={theme.palette.background.default}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="colored"
        />
        {hasNavigation && <Navigation />}
        <Box display={'flex'} flexDirection={'column'} minHeight={'100%'}>
          <Box
            flexGrow={1}
            display={'flex'}
            flexDirection={'column'}
            marginTop={hasNavigation ? '10vh' : null}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <AppRoutes />
            </Suspense>
          </Box>
        </Box>
      </ErrorBoundary>
    </Box>
  );
};

export default App;
