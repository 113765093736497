import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import useAuthContext from '../firebase/auth/auth-context';

import Login from './Login';

const Metronome = lazy(() => import('../modules/Metronome/Metronome'));
const SongList = lazy(() => import('./../modules/SongList/SongList'));
const SetLists = lazy(() => import('../modules/SetLists/SetLists'));
const SetList = lazy(() => import('../modules/SetList/SetList'));
const LiveMode = lazy(() => import('../modules/LiveMode/LiveMode'));
const ResetLiveMode = lazy(() => import('../modules/LiveMode/ResetLiveMode'));
const Setup = lazy(() => import('../modules/Setup/Setup'));

const AppRoutes = () => {
  const { user } = useAuthContext();

  return (
    <Routes>
      {user ? (
        <>
          <Route path="/setup" element={<Setup />} />
          <Route path="/setlists" element={<SetLists />} />
          <Route path="/setlists/:id" element={<SetList />} />
          <Route path="/livemode" element={<LiveMode />} />
          <Route path="/livemode/:id" element={<ResetLiveMode />} />
          <Route path="/songlist" element={<SongList />} />
          <Route path="*" index element={<Metronome />} />
        </>
      ) : (
        <>
          <Route path="login" index element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
