import { getDocs, query, where } from 'firebase/firestore/lite';

import { BasicDataService } from './basic.service';

export const userModul = ({ name, email }) => ({
  name,
  email,
});

class UserDataService extends BasicDataService {
  constructor() {
    super('users');
  }

  getByEmail = email => {
    const docRef = query(this.collectionRef, where('email', '==', email));
    return getDocs(docRef);
  };
}

export default new UserDataService();
