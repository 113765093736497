import { createContext, useContext } from 'react';

const AppContext = createContext({
  baseUrl: 'http://localhost:3000',
  isDevMode: process.env.NODE_ENV === 'development',
});

export const AppContextProvider = props => {
  const baseUrl = process.env.REACT_APP_FRONTEND_URL;
  const isDevMode = process.env.NODE_ENV === 'development';
  return <AppContext.Provider value={{ baseUrl, isDevMode }}>{props.children}</AppContext.Provider>;
};

const useAppContext = () => useContext(AppContext);

export default useAppContext;
