import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore/lite';

import { auth, db } from '../config/firebase.config';

export class BasicDataService {
  constructor(collectionTitle) {
    this.collectionRef = collection(db, collectionTitle);
    this.userId = auth?.currentUser?.uid;
    this.whereUserId = where('userId', '==', this.userId);
  }

  addUidToValue = value => {
    const user = auth.currentUser;
    return { ...value, userId: user.uid };
  };

  getAll = async () => {
    const getAllQuery = query(this.collectionRef, this.whereUserId);
    const docList = await getDocs(getAllQuery);
    return docList;
  };

  getById = async id => {
    const docRef = doc(this.collectionRef, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().userId === this.userId) {
      return docSnap;
    } else {
      return null;
    }
  };

  createOrUpdate = async (values, id = null) => {
    let docRef;
    if (!!id) {
      // Update document
      docRef = doc(this.collectionRef, id);
    } else {
      // Create new document
      docRef = doc(this.collectionRef);
    }
    await setDoc(docRef, this.addUidToValue(values));
    return docRef.id;
  };

  delete = async id => {
    const docRef = doc(this.collectionRef, id);
    await deleteDoc(docRef);
  };
}
