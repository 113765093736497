import { Checkbox, FormControlLabel } from '@mui/material';

const CheckboxInput = ({ input, label, meta, ...rest }) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          {...input}
          onChange={event => input.onChange(event.target.checked)}
          variant={'standard'}
          {...rest}
        />
      }
    />
  );
};

export default CheckboxInput;
