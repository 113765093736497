import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { AuthContextProvider } from '../firebase/auth/auth-context';

import { AppContextProvider } from './../context/app-context';
import { LiveModeContextProvider } from './../context/live-mode-context';
import { UserSetupContextProvider } from './../context/user-setup-context';
import App from './App';
import theme from './mui.theme';

const queryClient = new QueryClient();

const AppWrapper = () => (
  <AppContextProvider>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <UserSetupContextProvider>
            <QueryClientProvider client={queryClient}>
              <LiveModeContextProvider>
                <App />
              </LiveModeContextProvider>
            </QueryClientProvider>
          </UserSetupContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </AppContextProvider>
);

export default AppWrapper;
