import { Field, Form } from 'react-final-form';
import { Box, Paper, Typography } from '@mui/material';

import TextFieldInput from '../components/FormInputs/text-field-input';
import useAuthContext from '../firebase/auth/auth-context';

import BasicButton from './../components/BasicButton/basic-button';
import CheckboxInput from './../components/FormInputs/checkbox-input';

const Login = () => {
  const { login } = useAuthContext();

  return (
    <Box
      className={'login-wrapper'}
      flexGrow={1}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Paper className={'login-box'} elevation={4}>
        <Box display={'flex'} flexDirection={'column'} margin={2}>
          <Typography variant={'h4'}>Login</Typography>
          <Form
            onSubmit={async values => {
              await login(values);
            }}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Field name={'email'} label={'E-Mail'} required component={TextFieldInput} />
                    <Field
                      name={'password'}
                      label={'Passwort'}
                      type={'password'}
                      required
                      component={TextFieldInput}
                    />
                    <Field
                      name={'rememberMe'}
                      label={'Anmeldung speichern'}
                      component={CheckboxInput}
                      defaultChecked
                    />
                    <Box marginTop={2}>
                      <BasicButton type={'submit'} disabled={submitting}>
                        Login
                      </BasicButton>
                    </Box>
                  </Box>
                </form>
              );
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
