import { createContext, useContext, useMemo } from 'react';

import useLocalStorage from './../hooks/local-storage-hook';

const LiveModeContext = createContext({
  songList: [],
  songIndex: 0,
  activeSong: null,
  durationInSeconds: 0,
  setlistTitle: null,
  setlistBand: null,
  setLiveMode: () => {},
  setDurationInSeconds: () => {},
});

export const LiveModeContextProvider = props => {
  const [setlistTitle, setSetlistTitle] = useLocalStorage('live-mode-title', null);
  const [setlistBand, setSetlistBand] = useLocalStorage('live-mode-band', null);
  const [songList, setSongList] = useLocalStorage('live-mode-song-list', []);
  const [songIndex, setSongIndex] = useLocalStorage('live-mode-song-index', 0);
  const [durationInSeconds, setDurationInSeconds] = useLocalStorage('live-mode-duration', 0);

  const setLiveMode = ({ newSongList, newSongIndex, resetDuration, title, band }) => {
    if (!!newSongList) {
      setSongList(newSongList.map((song, index) => ({ ...song, number: index + 1 })));
    }
    if (!!newSongIndex || newSongIndex === 0) {
      setSongIndex(newSongIndex);
    }
    if (resetDuration) {
      setDurationInSeconds(0);
    }
    if (title !== undefined) {
      setSetlistTitle(title);
      setSetlistBand(band);
    }
  };

  const activeSong = useMemo(
    () => (songList.length > 0 ? songList[songIndex] : null),
    [songIndex, songList],
  );

  return (
    <LiveModeContext.Provider
      value={{
        songList,
        songIndex,
        activeSong,
        durationInSeconds,
        setlistTitle,
        setlistBand,
        setLiveMode,
        setDurationInSeconds,
      }}
    >
      {props.children}
    </LiveModeContext.Provider>
  );
};

const useLiveModeContext = () => {
  const { songList, setLiveMode, ...liveModeContext } = useContext(LiveModeContext);

  return {
    validLiveMode: songList.length > 0,
    songList,
    setLiveMode,
    ...liveModeContext,
  };
};

export default useLiveModeContext;
