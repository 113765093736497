import { TextField } from '@mui/material';

const TextFieldInput = ({ input, meta, ...rest }) => {
  return (
    <TextField
      {...input}
      onChange={event => input.onChange(event.target.value)}
      error={meta.touched && !!meta.error}
      helperText={meta.touched ? meta.error : ''}
      variant={'standard'}
      {...rest}
    />
  );
};

export default TextFieldInput;
