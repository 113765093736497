import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import BasicButton from '../../components/BasicButton/basic-button';

const NavItem = (props) => {
  const { name, smallName, link, rootLink, disabled } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const isActive = useMemo(() => {
    const pathname = location.pathname;
    return (rootLink && pathname.length < 2) || pathname.includes(link);
  }, [location.pathname, link, rootLink]);

  return (
    <Box flex={'1 1 0px'} height={'100%'}>
      <BasicButton
        onClick={() => navigate(link)}
        color={isActive ? 'secondary' : 'primary'}
        smallChildren={smallName}
        disabled={disabled}
      >
        {name}
      </BasicButton>
    </Box>
  );
};

export default NavItem;
