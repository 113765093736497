import { createContext, useContext } from 'react';

import clickWaveHighType0 from '../assets/cowbell_high.wav';
import clickWaveLowType0 from '../assets/cowbell_low.wav';
import clickWaveHighType1 from '../assets/metronome_high.wav';
import clickWaveLowType1 from '../assets/metronome_low.wav';

import useLocalStorage from './../hooks/local-storage-hook';

const liveModeConfigInit = {
  showLeadSheet: false,
  showPlayButton: false,
  furtherSongs: null,
};
const metronomeConfigInit = {
  clickType: 0,
  clickWaveHigh: clickWaveHighType0,
  clickWaveLow: clickWaveLowType0,
};

const UserSetupContext = createContext({
  liveModeConfig: liveModeConfigInit,
  setConfigParameter: () => {},
});

export const UserSetupContextProvider = props => {
  const [liveModeConfig, setLiveModeConfig] = useLocalStorage(
    'live-mode-config',
    liveModeConfigInit,
  );
  const [metronomeConfig, setMetronomeConfig] = useLocalStorage(
    'metronome-config',
    metronomeConfigInit,
  );

  const setConfigParameter = (parameter, value) => {
    switch (parameter) {
      case 'liveModeConfig.showLeadSheet':
        setLiveModeConfig({ ...liveModeConfig, showLeadSheet: value });
        break;
      case 'liveModeConfig.showPlayButton':
        setLiveModeConfig({ ...liveModeConfig, showPlayButton: value });
        break;
      case 'liveModeConfig.furtherSongs':
        setLiveModeConfig({ ...liveModeConfig, furtherSongs: value });
        break;
      case 'metronomeConfig.clickType':
        switch (value) {
          case '1':
            setMetronomeConfig({
              ...metronomeConfig,
              clickType: 1,
              clickWaveHigh: clickWaveHighType1,
              clickWaveLow: clickWaveLowType1,
            });
            break;
          case '0':
          default:
            setMetronomeConfig({
              ...metronomeConfig,
              clickType: 0,
              clickWaveHigh: clickWaveHighType0,
              clickWaveLow: clickWaveLowType0,
            });
        }
        break;
      default:
      // nothing
    }
  };

  return (
    <UserSetupContext.Provider
      value={{
        liveModeConfig,
        metronomeConfig,
        setConfigParameter,
      }}
    >
      {props.children}
    </UserSetupContext.Provider>
  );
};

const useUserSetupContext = () => useContext(UserSetupContext);

export default useUserSetupContext;
